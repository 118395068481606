<script>
  import { onMount } from "svelte";
  import fetch from "isomorphic-fetch";

  let first = false;
  let second = false;

  onMount(() => {
    const timeout1 = setTimeout(() => {
      first = true;
    }, 2000);

    const timeout2 = setTimeout(() => {
      second = true;
    }, 4000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  });

  let sending = false;
  let input = "";
  let sentMessages = [];

  function sendMessage() {
    if (sending) {
      return;
    }

    sending = true;

    fetch(`/api/send-message`, {
      method: "POST",
      body: JSON.stringify({
        message: input
      })
    })
      .then(async res => {
        if (res.status === 200) {
          const body = await res.json();
          console.log(body);
          if (body.success) {
            sentMessages = [...sentMessages, input];
            input = "";
            return;
          }
        }
      })
      .catch(err => {
        console.error(err);
      })
      .then(() => (sending = false));
  }
</script>

<style>
  .textarea-wrapper {
    display: flex;
    margin: 0 auto;
  }

  .textarea-wrapper,
  textarea {
    width: 300px;
  }
</style>

<div class="container">
  {#if !first}
    <div class="bubble">
      <div class="container-circle">
        <div class="circle cc1" id="circle1" />
        <div class="circle cc2" id="circle2" />
        <div class="circle cc3" id="circle3" />
      </div>
    </div>
  {:else}
    <div class="bubble">
      Hi there. I'm an abusive POS who never let my wife hang out with her
      friends, and when she would say anything I would kick her.
    </div>

    {#if !second}
      <div class="bubble">
        <div class="container-circle">
          <div class="circle cc1" id="circle1" />
          <div class="circle cc2" id="circle2" />
          <div class="circle cc3" id="circle3" />
        </div>
      </div>
    {:else}
      <div class="bubble">
        I also refered to her friends as faggots. I'd love it if you sent me
        your thoughs.
      </div>
    {/if}

    {#each sentMessages as message}
      <div class="bubble bubble--alt">{message}</div>
    {/each}
  {/if}
</div>

<div class="textarea-wrapper">
  <textarea disabled={sending || !second} bind:value={input} />
  <button disabled={sending || !second} on:click={sendMessage}>Send</button>
</div>
